import { cbYmDataLayerPromo, setYmDataLayerPromo } from '../../../../../js/common/functions';
import { createElement } from '../../common/functions';
import adTooltip from './adTooltip';

export default function initbannerMain(page = 'main', sectionId) {
    console.log('init banner.js', page, sectionId);

    let ajaxUrl = '/local/ajax/getBanners.php';
    let debounce;

    switch(page) {
        case 'main':
            ajaxUrl = ajaxUrl + '?type=main';
            break;
        case 'catalog':
            ajaxUrl = ajaxUrl + '?type=catalog&section=' + sectionId;
            break;
    }

    const ajax = $.ajax({
        type: 'POST',
        url: ajaxUrl,
        processData: false,
        contentType: false,
    });

    ajax.done((res) => {
        const result = getResult(res);

        for (const key in result) {
            switch(key) {
                case 'header':
                    bannerHeader(result[key]);
                    break;
                case 'promo':
                    bannerPromo(result[key]);
                    break;
                case 'main':
                    bannerMain(result[key]);
                    break;
            }
        }
    });

    function getResult(res) {
        res = JSON.parse(res);

        const types = {
            main: ['banners_main_slide', 'banners_section_slide', 'banners_sale', 'banners_hit', 'banners_new'],
            promo: ['banners_main_sidebar1', 'banners_main_sidebar2'],
            header: ['banner-header']
        };

        for (const key in types) {
            let isSetArr = false;

            types[key].forEach(name => {
                if(!res.hasOwnProperty(name)) return
                types[key] = res[name];
                isSetArr = true;
            })

            if(!isSetArr) {
                types[key] = isSetArr;
            }
        }

        return types;
    }

    function bannerHeader(result) {
        if(!result) return;

        const banner = createSimpleBanner(result, ['happy']);
        const header = document.querySelector('header');

        header.insertAdjacentElement('afterbegin', banner);

        setTimeout(() => {
            banner.classList.add('active');
            header.closest('.wrap').classList.add('noprint_margin');
        })
    }

    function bannerPromo(result) {
        const banner = document.querySelector('.banner-promo');

        if(!banner) return;

        if(!result) {
            banner.remove();
            return;
        } 

        createSimpleBanner(result, banner);
    }

    function bannerMain(result) {
        const banner = document.querySelector('.main-banner');

        let selector = banner ? '.main-banner' : '.main-slider';

        const sliderWrap = document.querySelector(`${selector} .swiper-wrapper`);

        if(!sliderWrap) return;

        const wrapper = 
            sliderWrap.closest('.main-banner__wrapper') || 
            sliderWrap.closest('.main-baner-box');

        if(!result || !result.length) {
            if(wrapper) {
                wrapper.remove();
            }
            
            return;
        }

        const plug = sliderWrap.querySelector('.plug');
        const container = document.createDocumentFragment();

        result.forEach((obj, index) => {
            const item = createSwiperSlide(obj, index);
            container.append(item);
        });

        sliderWrap.append(container);

        if(plug) {
            plug.remove();
        }

        const swiper = new Swiper(selector, {
            init: false,
            preloadImages: false,
            lazy: {
                enabled: true,
                loadPrevNext: true,
            },
            slidesPerView: 1,
            spaceBetween: 15,
            pagination: {
                el: wrapper ? wrapper.querySelector('.swiper-pagination') : '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: wrapper ? wrapper.querySelector('.swiper-arrow--next') : false,
                prevEl: wrapper ? wrapper.querySelector('.swiper-arrow--prev') : false,
            },
        });

        let currentIndex = swiper.activeIndex;

        swiper.on('init', function() { 
            cbYmDataLayerPromo({
                swiper: this,
                swiperEvent: 'init'
            }) 
        });

        swiper.on('activeIndexChange', function() { 
            let rtl = true;

            if(currentIndex > this.activeIndex) {
                rtl = false;
            }

            currentIndex = this.activeIndex;

            cbYmDataLayerPromo({
                swiper: this,
                swiperEvent: 'activeIndexChange',
                rtl
            }) 
        });
        
        swiper.init();

        const sliderContainer = wrapper.closest('.main-banner__container');

        if(sliderContainer) {
            setShowBanner(sliderContainer, wrapper);
        }
    }

    function createSwiperSlide(obj, index) {
        const slide = createElement('div', ['main-slider__item', 'swiper-slide']);
        let img;

        if(index === 0) {
            img = createElement('img');
            img.src = obj.img;
        } else {
            img = createElement('img', ['swiper-lazy']);
            img.dataset.src = obj.img;
        }

        if(obj.hasOwnProperty('id')) {
            slide.dataset.promoId = obj.id
        }

        if(obj.hasOwnProperty('name')) {
            slide.dataset.promoName = obj.name
        }

        if(obj.hasOwnProperty('link') && obj.link.length) {
            const link = createElement('a', []);

            link.href = obj.link;
            link.append(img);

            link.addEventListener('click', e => {
                e.preventDefault();

                clearTimeout(debounce);
                debounce = setTimeout(() => {
                    const arr = [{
                        id: slide.dataset.promoId,          
                        name: slide.dataset.promoName,
                        creative: "Баннер на главной",
                        position: index + 1
                    }];

                    setYmDataLayerPromo(arr, 'promoClick');
                    location.href = obj.link;
                }, 300)
            })

            slide.append(link);
        } else {
            slide.append(img);
        }

        if (obj.erid !== undefined && obj.erid !== null && obj.erid !== '') {
            slide.setAttribute('data-erid', obj.erid);
        }
        if (obj.mark !== undefined && obj.mark !== null && obj.mark !== '') {
            slide.setAttribute('data-mark', obj.mark);
        }

        adTooltip(slide);
        return slide;
    }

    function createSimpleBanner(result, wrapperSlectors) {
        const a = Array.isArray(wrapperSlectors)
            ? createElement('a', wrapperSlectors)
            : wrapperSlectors;
        const img = createElement('img', []);
        
        a.href = result[0].link;

        img.src = result[0].img;
        img.alt = result[0].title;

        if(result[0].webp) {
            const picture = createElement('picture', []);
            const source = createElement('source', []);

            source.srcset = result[0].webp;
            source.type = 'image/webp';

            picture.append(source);
            picture.append(img);
            a.append(picture);
        } else {
            a.append(img);
        }

        return a;
    }

    function setShowBanner(parent, wrap) {
        const block = parent.querySelector('.main-banner__block');

        if(!block) return;

        const btn = block.querySelector('.main-banner__block-close');
        const title = block.querySelector('.main-banner__block-title');

        btn.addEventListener('click', e => {
            e.preventDefault();

            const isActive = btn.classList.contains('active');
            let date = new Date(new Date().setMonth(new Date().getMonth() + 3));

            window.setCookie(btn.dataset.cookieName, isActive ? 1 : 0, {
                path:'/catalog',
                expires: date
            });

            title.textContent = isActive ? 'Показать баннеры' : 'Скрыть баннеры';

            isActive ? $(wrap).slideUp() : $(wrap).slideDown();
            isActive ? btn.classList.remove('active') : btn.classList.add('active');
        });
    }
}

