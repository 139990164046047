import { setTabSlider } from '../../common/functions';
import initMainBanner from '../../includes/shares/main-banner';

document.addEventListener('DOMContentLoaded', function () {
    // inint first slider
    const catalogSlidersContent = document.querySelectorAll('.catalog-slider__content-inner');

    if(catalogSlidersContent.length) {
        catalogSlidersContent.forEach(catalogSlider => {
            if(!catalogSlider) return;

            setTabSlider({
                slider: catalogSlider,
                count: 4,
                classParent: `.catalog-slider__content-wrapper`,
                classPrev: '.catalog-slider__arrow-prev',
                classNext: '.catalog-slider__arrow-next',
                space: 35,
            }).init();
        })
    }
});

window.addEventListener('load', function () {
    initMainBanner();
});